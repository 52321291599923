import CryptoJS from 'crypto-js';
import { AES_SECRET } from '@/misc/constants';

const mixin = {
    data () {
        return {
            userSearchedObject: {}
        };
    },
    created () {
        this.setImportedConstants();
    },
    methods: {
        setImportedConstants () {
          this.$options.AES_SECRET = AES_SECRET;
        },
        goToUserProfile (user, selectedTab) {
            const hashedId = CryptoJS.AES.encrypt(user.id.toString(), this.$options.AES_SECRET).toString();
            const queryParams = {};

            if (selectedTab) {
                queryParams.selectedTab = selectedTab;
            }

            this.$router.push({
                name: 'social.user-profile',
                params: { query: `${user.username}-${hashedId}` },
                query: queryParams
            })
            .then(failure => {
                console.log(failure);
            });
        }
    }
};

export default mixin;
